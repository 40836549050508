import React, { ReactNode } from 'react'
import {motion} from "framer-motion"
import "../style/style.css"
const BlackAnimate = ({children} :{children:ReactNode}) => {
    const black = {
        initial: {
            height:"100vh",
            bottom:0,
        },
        animate: {
            height:0,
            transition: {
                duration:2,
                delay:0.5,
                ease:[0.87,0,0.13,1]
            }
        }
    }

  return (
    <div className='box'>
        <motion.div
            initial="initial"
            animate="animate"
            variants={black}
            className='black_box'
            onAnimationStart={() => document.body.classList.add("overflow-hidden")}
            onAnimationComplete={() => document.body.classList.remove("overflow-hidden")}
        >
            {children}
        </motion.div>
    </div>
  )
}

export default BlackAnimate; 