import React from 'react';
import './App.css';
import {Route, Routes, useLocation } from 'react-router-dom';
import TopPage from './pages/TopPage';
import NotFound from './pages/NotFound';
import ContentsPage from './pages/ContentsPage';
import ContactPage from './pages/ContactPage';
import { AnimatePresence } from 'framer-motion';

function App() {

  const location = useLocation();

  return (
    <div className="App">

      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route index element={<TopPage/>} />
          <Route path={"/contents"} element={<ContentsPage/>}/>
          <Route path={"/contact"} element={<ContactPage/>}/>
          <Route path={"/*"} element={<NotFound/>} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
