import React, { ReactNode } from 'react'
import {motion} from "framer-motion"
const HoverDiv = ({children} :{children:ReactNode}) => {
  return (
    <div>
        <motion.div
        whileHover={{ scale: 1.2 }}
        onHoverStart={e => {}}
        onHoverEnd={e => {}}
        >
            {children}    
        </motion.div>
    </div>
  )
}

export default HoverDiv;