import React from 'react'
import discordBot from "../assets/discord_bot.png";
import HoverDiv from '../components/utils/HoverDiv';
import ManagedFoodloss from "../assets/managed-foodloss.png";
import MySite from "../assets/mysite.png";
import { Typography, Card, CardMedia, CardContent,Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: 'transparent',
  }),
}));
const ContentsPage = () => {
  return (
      <div>
        <Grid container spacing={4} sx={{ mt: 8, mb: 5, overflowX: 'hidden'}}>
          <Grid size={4}>
            <Item>
              <HoverDiv>
              <Card sx={{ height: '100%' }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={discordBot}
                  alt="not"
                />
                <CardContent>
                  <Typography variant="h6">discord-bot</Typography>
                  <Typography variant="body2" color="text.secondary">
                    minecraftサーバーをコマンド入力で自動起動してくれるbot
                  </Typography>
                </CardContent>
              </Card>
              </HoverDiv>
            </Item>
          </Grid>

          <Grid size={4}>
            <Item>
              <HoverDiv>
                <Card sx={{ height: '100%' }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={MySite}
                    alt="not"
                  />
                  <CardContent>
                    <Typography variant="h6">this site</Typography>
                    <Typography variant="body2" color="text.secondary">
                      react学習用で作成
                    </Typography>
                  </CardContent>
                </Card>
              </HoverDiv>
            </Item>
          </Grid>

          <Grid size={4}>
            <Item>
              <HoverDiv>
                <Card sx={{ height: '100%' }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={ManagedFoodloss}
                    alt="not"
                  />
                  <CardContent>
                    <Typography variant="h6">managed-foodloss</Typography>
                    <Typography variant="body2" color="text.secondary">
                      ハッカソン参加時フロントエンドを作成
                    </Typography>
                  </CardContent>
                </Card>
              </HoverDiv>
            </Item>
          </Grid>
        </Grid>
      </div>
  )
}

export default ContentsPage;