import React from 'react';
import {motion} from "framer-motion";
import BlackAnimate from '../components/utils/BlackAnimate';
import { Box, Typography, Button, Card, CardMedia, CardContent,Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import discordBot from "../assets/discord_bot.png";
import HoverDiv from '../components/utils/HoverDiv';
import ManagedFoodloss from "../assets/managed-foodloss.png";
import MySite from "../assets/mysite.png";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: 'transparent',
  }),
}));


const TopPage = () => {
  return (
    <BlackAnimate>
      <motion.div
        initial={{opacity: 0}}
        animate={{opacity:1}}
        transition={{duration:1.2,delay:2}}
        exit={{opacity:0}}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width:"100%",
            overflowX: 'hidden',
          }}
        >
          <Typography variant="h1" sx={{ mb: 3 }}>
            Welcome to My Portfolio
          </Typography>
          <Typography variant="h5" sx={{ mb: 5 }}>
            my projects or contact?<br />
            ※テスト中につきボタンをクリックしても反応いたしません
          </Typography>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Button variant="contained" size="large" href='contents' sx={{backgroundColor:"#1D3D5E"}}>
              Projects
            </Button>
            <Button variant="contained" size="large" sx={{backgroundColor:"#7b9098"}}>
              Contact
            </Button>
          </Box>
        </Box>

        <Grid container spacing={4} sx={{ mt: 8, mb: 5, overflowX: 'hidden'}}>
          <Grid size={4}>
            <Item>
              <HoverDiv>
              <Card sx={{ height: '100%' }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={discordBot}
                  alt="not"
                />
                <CardContent>
                  <Typography variant="h6">discord-bot</Typography>
                  <Typography variant="body2" color="text.secondary">
                    minecraftサーバーをコマンド入力で自動起動してくれるbot
                  </Typography>
                </CardContent>
              </Card>
              </HoverDiv>
            </Item>
          </Grid>

          <Grid size={4}>
            <Item>
              <HoverDiv>
                <Card sx={{ height: '100%' }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={MySite}
                    alt="not"
                  />
                  <CardContent>
                    <Typography variant="h6">this site</Typography>
                    <Typography variant="body2" color="text.secondary">
                      react学習用で作成
                    </Typography>
                  </CardContent>
                </Card>
              </HoverDiv>
            </Item>
          </Grid>

          <Grid size={4}>
            <Item>
              <HoverDiv>
                <Card sx={{ height: '100%' }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={ManagedFoodloss}
                    alt="not"
                  />
                  <CardContent>
                    <Typography variant="h6">managed-foodloss</Typography>
                    <Typography variant="body2" color="text.secondary">
                      ハッカソン参加時フロントエンドを作成
                    </Typography>
                  </CardContent>
                </Card>
              </HoverDiv>
            </Item>
          </Grid>
        </Grid>
      </motion.div>
    </BlackAnimate>

  )
}

export default TopPage;